import React from "react";
import { Helmet } from 'react-helmet';
import "./UserDashboard.css";
import Logo from "../../Images/Logos/gold-basket-token-1.png";
import defaultUserPhoto from "../../Images/default-pfp.png";



import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAward, faUser, faEnvelope, faPeopleGroup } from '@fortawesome/free-solid-svg-icons';


// User Dashboard
function UserDashboard() {
    // Check if user is defined and has a rounds property before trying to access it

    let user = "";
    // get user from local storage
    if (localStorage.getItem("user")) {
        user = JSON.parse(localStorage.getItem("user"));
    }
    else {
        // redirect home if user is not signed in
        alert("You must be signed in to view this page.");
        window.location.href = "/";
    }

    // get user info from user variable
    let name = user.name;
    let email = user.email;
    let firstName = name.split(' ')[0] || "";
    let lastName = name.split(' ')[1] || "";
    // let userPhoto = user.photoUrl;
    let userPhoto = ""
    let userSavedRounds = user.userSavedRounds ? user.userSavedRounds.rounds : [];
    userSavedRounds = JSON.parse(JSON.stringify(userSavedRounds));

    /**
     * ---------------------
     * User Dashboard Page
     * ---------------------
     */
    return (
        <div className="container">
            <Helmet>
                <title>User Dashboard</title>
                <link rel="icon" type="image/png" href={Logo}/>
            </Helmet>
            <div className="user-heroBackground" />
            <div className="user-hero">
                <div className="heroContent">
                    <h1 className="user-dash-title">User Dashboard</h1>
                </div>
            </div>

            <div className="body">
                { /* User Dash Nav. Functionality to be added later... */ }
                <div className="userDashNav">
                    {/* User Profile Pic */}
                    <div className="userOverview">
                        <div className="userPhotoBorder" />
                        <img className="userPhoto" src={userPhoto} alt="User Pfp"/>
                    </div>

                    { /* User's Display Name & Title, Join Date */ }
                    <div className="userNavInfo">
                        <div className="userDisplayName">{firstName} {lastName}</div>
                        {/* <div className="userTitle">DG Course Caddy Dev, 11/2023</div> */}
                    </div>

                    { /* User Options */ }
                    {/* <div className="userOptionsContainer">
                        <div className="userOption">
                            <button className="userOptionButton">Edit Profile</button>
                        </div>
                    </div> */}
                </div>

                { /* User Dash Body */ }
                <div className="userDashBody">
                    <div className="userInfo">
                        <div className="userInfoTitle">User Info</div>
                        <div className="userInfoLine">
                        <FontAwesomeIcon className="userInfoIcon nameIcon" icon={faUser} />
                            <div className="userInfoText">{firstName} {lastName}</div>
                        </div>
                        {/* <div className="userInfoLine">
                            <FontAwesomeIcon className="userInfoIcon emailIcon" icon={faEnvelope} />
                            <div className="userInfoText">{email}</div>
                        </div> */}
                        {/* <div className="userInfoLine">
                        <FontAwesomeIcon className="userInfoIcon joinedIcon" icon={faPeopleGroup} />
                            <div className="userInfoText">Member Since 09/2023</div>
                        </div>
                        <div className="userInfoLine">
                        <FontAwesomeIcon className="userInfoIcon awardIcon" icon={faAward} />
                            <div className="userInfoText">The Coolest Dev The Planet Has Ever Seen</div>
                        </div> */}
                    </div>
                </div>

            </div>
            <div className="footer">
                <p className="footerText">Made with <span className="heart">&#9829;</span> by the DG Course Caddy Team</p>
            </div>
        </div>
    );
};

export default UserDashboard;
