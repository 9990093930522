import React from 'react';
import { StyleSheet, css } from 'aphrodite';

import ContactForm from './ContactForm';

import imageOverlay from '../../Images/Backgrounds/background2.jpg';


// Contact component
class Contact extends React.Component {
    render() {
      return (
        <div className={css(styles.container)}>
          < ContactForm />
        </div>
      );
    }
};

// Stylesheet
const styles = StyleSheet.create({
    container: {
        textAlign: "center",
        backgroundImage: `url(${imageOverlay})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        color: "#ffffff",
        paddingTop: "80px",
        height: "100vh",
        /* align items to center */
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    }
});

export default Contact;