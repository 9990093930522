import React, { useState, useEffect } from 'react';
import { db } from '../../firebaseConfig';
import { StyleSheet, css } from 'aphrodite/no-important';
import { collection, getDocs } from "firebase/firestore";
import { doc, updateDoc } from "firebase/firestore";

import gearIcon from "../../Images/Icons/gear-icon.png";

import disc1 from "../../Images/Icons/Discs/pd2-blue.png";
import disc2 from "../../Images/Icons/Discs/pd2-orange-2.png";
import disc3 from "../../Images/Icons/Discs/pd2-orange.png";
import disc4 from "../../Images/Icons/Discs/pd2-pink.png";
import disc5 from "../../Images/Icons/Discs/pd2-red.png";
import disc6 from "../../Images/Icons/Discs/pd2-white.png";
import disc7 from "../../Images/Icons/Discs/pd2-yellow.png";


const DataFetch = () => {
    const [calculatedScore, setCalculatedScore] = useState(null);
    const [courses, setCourses] = useState([]);
    const [selectedCourse, setSelectedCourse] = useState('');
    const [date, setDate] = useState('');
    const [time, setTime] = useState('');
    const [scoreDifferential, setScoreDifferential] = useState('');
    const [error] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        console.log("DataFetch component rendered!");
        const coursesRef = collection(db, 'course-data');

        const fetchCourses = async () => {
            const querySnapshot = await getDocs(coursesRef);
            // convert querySnapshot to string object

            console.log(`Query Snapshot: ${querySnapshot}`)
            const courseArray = querySnapshot.docs.map(doc => doc.id); // replace 'name' with the field name in your document that holds the course name

            // capitialize the first letter of each word in the course name, replace hypens with spaces
            const formattedCourseName = courseArray.map(course => {
                return course.replace(/\b\w/g, l => l.toUpperCase()).replace(/-/g, ' ');
            }
            );

            console.log(`Course Array: ${courseArray}`);
            setCourses(formattedCourseName);
        };

        fetchCourses();
    }, []);

    const formatCourseName = (name) => {
        return name
            .toLowerCase()
            .replace(/'/g, '')
            .trim()
            .replace(/\s+-\s+/g, '-')
            .replace(/\s+/g, '-');
    }

    const handleSubmit = async () => {
        setIsLoading(true);
        const formattedCourseName = formatCourseName(selectedCourse);
        let requestUrl = `https://getrating-oe45jcpija-uc.a.run.app?score=${scoreDifferential}&course=${formattedCourseName}&date=${date}&time=${time}:00`;

        console.log("Generated request URL:", requestUrl); // Added for URL Verification

        try {
            const response = await fetch(requestUrl, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            if (!response.ok) {
                console.error('Request failed with status:', response.status, 'for URL:', requestUrl); // Enhanced error logging
                throw new Error('Network response was not ok');
            }

            const result = await response.json();
            setIsLoading(false);
            setCalculatedScore(result);

        } catch (error) {
            console.error("Error making or parsing the request for URL:", requestUrl, "Error:", error); // Enhanced error logging
        }
    };

    if (error) {
        return <div>Error fetching courses: {error.message}</div>;
    }

    const handleClear = () => {
        setSelectedCourse('');
        setDate('');
        setTime('');
        setScoreDifferential('');
        setCalculatedScore(null);
    };

    const handlePlus = () => {
        // increment scoreDifferential by 1
        setScoreDifferential(Number(scoreDifferential) + 1);
    };

    const loadingImages = [ disc1, disc2, disc3, disc4, disc5, disc6, disc7 ];

    const getRandomLoadingImage = () => {
        const randomIndex = Math.floor(Math.random() * loadingImages.length);
        return loadingImages[randomIndex];
    };

    const isMobile = window.innerWidth <= 768;

    // check if the savedScore is already in the savedScores
    const scoreExists = (savedScores, savedScore) => {
        return savedScores.some(score =>
          score.course === savedScore.course &&
          score.date === savedScore.date &&
          score.time === savedScore.time &&
          score.score === savedScore.score &&
          score.rating === savedScore.rating
        );
      };

    const handleSaveScore = () => {
        // log the already saved scores from the user to the console
        console.log("Already saved scores:", localStorage.getItem('savedScores'));

        // savedScore object
        const savedScore = {
            course: selectedCourse,
            date: date,
            time: time,
            score: scoreDifferential,
            rating: calculatedScore,
            dateSaved: new Date(),
        };

        // get user object
        const user = JSON.parse(localStorage.getItem('user'));

        // get savedScores array from user object
        let savedScores = user.userSavedRounds.rounds;


        // if savedScore is already in the user's savedScores, alert the user. Dont check for dateSaved because it will always be different
        if (scoreExists(savedScores, savedScore)) {
            alert("Score already saved!");
            // end the function
            return;
        }

        // if savedScore is not already in the user's savedScores, add it to the savedScores
        else {
            // add the savedScore to the savedScores array
            savedScores.push(savedScore);

            // log the updated savedScores to the console
            console.log("Updated saved scores:", savedScores);

            // Store the user object back in local storage
            localStorage.setItem('user', JSON.stringify(user));

            // update the user document in Firestore
            const userRef = doc(db, "users", user.uid);
            updateDoc(userRef, {
                userSavedRounds: user.userSavedRounds
            });

            // alert the user that the score was saved
            alert("Score saved!");
        }
    };


    /**
     *
     * --------------------------------------------------
     * The Component
     * --------------------------------------------------
     *
     */
    return (
        <div className={css(styles.container)}>
            <h1 className={css(styles.formTitle)}>PDGA Rating Estimator</h1>
            <br/>
            { /* Course field */}
            <label className={css(styles.label)} >Course: </label>
                <select className={css(styles.courseSelect)}
                    value={selectedCourse}
                    onChange={e => setSelectedCourse(e.target.value)}
                >
                    <option value="" disabled>Select a course</option>
                    {courses.map(course => <option key={course} value={course}>{course}</option>)}
                </select>

                { /* Date field */}
                <div>
                    <label className={css(styles.label)} >Date: </label>
                    <input className={css(styles.input)} type="date" value={date} onChange={e => setDate(e.target.value)} />
                </div>

                { /* Time field */}
                <div>
                    <label className={css(styles.label)} >Time: </label>
                    <input className={css(styles.input)} type="time" value={time} onChange={e => setTime(e.target.value)} />
                </div>


            { /* Score field */}
            <div>
                <label className={css(styles.label)}>Score: </label>
                <div className={css(styles.scoreGroup)}>
                    { /* only load the plus and minus buttons if on mobile viewport */}
                    { isMobile && (
                        <button className={css(styles.scoreButton, styles.minus)} onClick={() => setScoreDifferential(scoreDifferential - 1)}>-</button>
                    )}
                    <input className={css(styles.scoreInput)}
                        type="number"
                        value={scoreDifferential}
                        onChange={e => setScoreDifferential(Number(e.target.value))}
                        placeholder="Ex. -1, +1"
                    />
                    { isMobile && (
                        <button className={css(styles.scoreButton, styles.plus)} onClick={handlePlus}>+</button>
                    )}
                </div>
            </div>

            { /* Submit button */}
            <div>
                <button className={css(styles.submitButtom)} onClick={handleSubmit}>Submit</button>
                <button className={css(styles.clearButtom)} onClick={handleClear}>Clear</button>
                { /* Conditionally load the result once it is recieved */ }
                { isLoading ?
                    <div className={css(styles.loadingContainer)}>
                        <img className={css(styles.loadingImage)} alt='loading icon' src={getRandomLoadingImage()}/>
                    </div>
                : ( calculatedScore != null &&
                    <div className={css(styles.Result)}>
                        <h3>Estimated Rating: {calculatedScore}
                        </h3>
                    </div>
                )}

            </div>

            { /* Save score Button, should only render if score is loaded */}
            { calculatedScore != null && (
                <div className={css(styles.saveScore)}>
                    <button className={css(styles.saveButton)} onClick={handleSaveScore} >Save Score</button>
                </div>
            )}

            { /* Settings Icon: button that will load a popup menu (popup menu to be implemented) */}
            <div className={css(styles.settings)} onClick={() => alert('Functionality to be added later!')}>
                <img src={gearIcon} alt="settings icon" className={css(styles.settingsIcon)} />
            </div>


        </div>
    );
}

// aphrodite CSS
const styles = StyleSheet.create({
    courseSelect: {
        border: '1px solid #232323',
        borderRadius: '10px',
        textAlign: 'center',
        height: '30px',
        width: '75%',
        padding: '6px 12px',
        display: 'inline-block',
        boxSizing: 'border-box',
        margin: '0',
        verticalAlign: 'middle',
        cursonr: 'pointer',

        // make fontsize responsive to desktop and mobile
        fontSize: '16px',
        '@media (max-width: 768px)': {
            width: '100%',
            fontSize: '12px',
        },
    },
    container: {

        height: '525px',

        // make the container responsive to desktop and mobile for height
        maxHeight: 'calc(100vh - 100px)',
    },

    // form title
    formTitle: {
        // make fontsize responsive to desktop and mobile
        fontSize: '30px',
        color: '#232323',
        '@media (max-width: 768px)': {
            fontSize: '20px',
        },
    },

    // label
    label: {
        color: '#232323',

        // labels on top of inputs
        display: 'block',
        padding: '5px',

        // make fontsize responsive to desktop and mobile
        fontSize: '20px',
        '@media (max-width: 768px)': {
            fontSize: '12px',
        },
    },

    // input
    input: {
        border: '1px solid #232323',
        borderRadius: '10px',
        textAlign: 'center',
        height: '30px',
        width: '75%',
        padding: '6px 12px',
        display: 'inline-block',
        boxSizing: 'border-box',
        margin: '0',
        // make fontsize responsive to desktop and mobile
        fontSize: '16px',
        '@media (max-width: 768px)': {
            width: '100%',
            fontSize: '12px',
        },
    },

    scoreInput: {
        border: '1px solid #232323',
        borderRadius: '10px',
        textAlign: 'center',
        height: '30px',
        width: '75%',
        padding: '6px 12px',
        display: 'inline-block',
        boxSizing: 'border-box',
        margin: '0',
        // make fontsize responsive to desktop and mobile
        fontSize: '16px',
        '@media (max-width: 768px)': {
            width: '80%',
            fontSize: '12px',
        },
    },

    // score group
    scoreGroup: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0px',
    },

    // score button
    scoreButton: {
        // button styles
        backgroundColor: 'rgb(88, 216, 110)',
        border: '1px solid rgba(23, 23, 23, 0.6)',
        borderRadius: '10px',
        color: '#000',

        // button sizes & positioning
        height: '30px',
        width: '50px',
        textAlign: 'center',
        display: 'inline-block',
        boxSizing: 'border-box',
        fontSize: '20px',

        // hover effect, turn button green over 0.5s
        ':hover': {
            backgroundColor: '#32CD32',
            color: '#232323',
            transition: '0.5s',
        },
        // transition the button back after not hovering over it
        ':not(:hover)': {
            transition: '1.0s',
        },
    },

    // minus button
    minus: {
        backgroundColor: 'rgb(226,112,112)',
        marginRight: '10px',

        // hover effect, turn button red over 0.5s
        ':hover': {
            backgroundColor: '#FF0000',
            color: '#232323',
            transition: '0.5s',
        },
    },

    // plus button
    plus: {
        backgroundColor: 'rgb(137, 207, 240)',
        marginLeft: '10px',

        // hover effect, turn button green over 0.5s
        ':hover': {
            backgroundColor: 'rgb(41,134,204)',
            color: '#232323',
            transition: '0.5s',
        },
    },


    // submit button
    submitButtom: {

        // button styles
        backgroundColor: 'rgb(137, 207, 240)',
        border: '1px solid rgba(23, 23, 23, 0.6)',
        borderRadius: '10px',
        color: '#000',
        cursor: 'pointer',

        // button sizes & positioning
        height: '30px',
        width: '35%',
        padding: '6px 12px',
        textAlign: 'center',
        display: 'inline-block',
        boxSizing: 'border-box',
        marginTop: '30px',
        marginBottom: '20px',
        marginRight: '5%',

        // make fontsize responsive to desktop and mobile
        fontSize: '16px',
        '@media (max-width: 768px)': {
            width: '47%',
            fontSize: '12px',
        },

        // hover effect, darken over 0.5s
        ':hover': {
            backgroundColor: 'rgb(41,134,204)',
            color: '#fff',
            transition: '0.5s',
        },
        // transition the button back after not hovering over it
        ':not(:hover)': {
            transition: '1.0s',
        },
    },

    // clear button
    clearButtom: {
        // button styles
        backgroundColor: 'rgb(226,112,112)',
        border: '1px solid rgba(23, 23, 23, 0.6)',
        borderRadius: '10px',
        color: '#000',
        cursor: 'pointer',

        // button sizes & positioning
        height: '30px',
        width: '35%',
        padding: '6px 12px',
        textAlign: 'center',
        display: 'inline-block',
        boxSizing: 'border-box',

        // make fontsize responsive to desktop and mobile
        fontSize: '16px',
        '@media (max-width: 768px)': {
            width: '47%',
            fontSize: '12px',
        },

        // hover effect, darken over 0.5s
        ':hover': {
            backgroundColor: '#FF0000',
            color: '#fff',
            transition: '0.5s',
        },
        // transition the button back after not hovering over it
        ':not(:hover)': {
            transition: '1.0s',
        },
    },

    // result
    Result: {
        color: '#232323',
        // make fontsize responsive to desktop and mobile
        fontSize: '24px',
    },

    // loading
    loadingContainer: {
        color: '#232323',
        // make fontsize responsive to desktop and mobile
        fontSize: '24px',
        '@media (max-width: 768px)': {
            fontSize: '16px',
        },
    },

    loadingImage: {
        height: '50px',
        width: '50px',

        // rotate the image 720 degrees over 2 seconds
        animationName: {
            '0%': {
                transform: 'rotate(0deg)',
            },
            '100%': {
                transform: 'rotate(720deg)',
            },
        },

        animationDuration: '2s',
        animationIterationCount: 'infinite',
    },

    // save score
    saveScore: {
        width: '50%',

        // align the text and the checkbox vertically on the same line
        display: 'flex',
        alignItems: 'center',
        verticalAlign: 'middle',

        // put the div at the bottom of the continaer
        position: 'absolute',
        bottom: '20px',
    },

    saveButton: {
        // button styles
        backgroundColor: 'rgb(88,216,109)',
        color: 'rgb(23, 23, 23)',
        border: '1px solid rgba(23, 23, 23, 0.6)',
        borderRadius: '10px',
        cursor: 'pointer',

        // button sizes & positioning
        height: '30px',
        width: '60%',
        padding: '6px 12px',
        textAlign: 'center',
        display: 'inline-block',
        boxSizing: 'border-box',

        // make fontsize responsive to desktop and mobile
        fontSize: '14px',

        // hover effect, turn button green over 0.5s
        ':hover': {
            backgroundColor: '#32CD32',
            color: 'rgb(255,242,235)',
            transition: '0.5s',
        },
        // transition the button back after not hovering over it
        ':not(:hover)': {
            transition: '1.0s',
        },
    },

    // settings
    settings: {
        width: '30px',
        height: '30px',

        position: 'absolute',
        bottom: '20px',
        right: '20px',


    },

    settingsIcon: {

        width: '100%',
        height: '100%',

        // put the div at the bottom of the continaer
        position: 'absolute',
        bottom: '0px',
        right: '0px',
        cursor: 'pointer',

        // rotate settingsIcon on hover
        ':hover': {
            // rotate the image 720 degrees over 2 seconds
            animationName: {
                '0%': {
                    transform: 'rotate(0deg)',
                },
                '100%': {
                    transform: 'rotate(720deg)',
                },
            },

            animationDuration: '2s',
            animationIterationCount: 'infinite',
        },
    },

});

export default DataFetch;
