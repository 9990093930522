import React from 'react';

function NotFound() {
    return (
        <div>
            <h1>404: Page Not Found</h1>
            <p>Sample text to see if this is actually working or not</p>
        </div>
    );
}

export default NotFound;