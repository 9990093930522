import React, { useState, useEffect } from "react";
import { stack as Menu } from "react-burger-menu";
import './Sidebar.css'
import settingsIcon from '../../Images/Icons/gear-icon.png';
import { NavLink, useLocation } from "react-router-dom";
import { signInWithGoogle, observeAuthState, auth } from "../../firebaseConfig";

// Side bar component
export default function Sidebar() {
    const [user, setUser] = useState(null);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        const unsubscribe = observeAuthState(user => {
            setUser(user);
        });

        // Cleanup subscription on unmount
        return () => unsubscribe();
    }, []);

    const signOut = async () => {
        try {
            await auth.signOut();
            // delete user info from local storage
            localStorage.removeItem('user');

            // redirect to home page
            window.location.assign('/');

            // alert user that they have signed out
            alert('You have signed out.');
        } catch (error) {
            console.error(error);
        }
    };

    const location = useLocation();

    const handleStateChange = (state) => {
        setIsOpen(state.isOpen);
      };

    return (
        <Menu right isOpen={isOpen} onStateChange={handleStateChange}>
            <NavLink to="/" className={location.pathname === "/" ? "activeLink" : ""} onClick={() => setIsOpen(false)}>Home</NavLink>
            <NavLink to="/rating-calculator" className={location.pathname === "/rating-calculator" ? "activeLink" : ""} onClick={() => setIsOpen(false)}>Rating Calculator</NavLink>
            {user && (
                <NavLink to="/scorecard" className={location.pathname === "/scorecard" ? "activeLink" : ""} onClick={() => setIsOpen(false)}>Scorecard</NavLink>
            )}
            {user && (
                <NavLink to="/userdashboard" className={location.pathname === "/userdashboard" ? "activeLink" : ""} onClick={() => setIsOpen(false)}>Profile</NavLink>
            )}
            <NavLink to="/about" className={location.pathname === "/about" ? "activeLink" : ""} onClick={() => setIsOpen(false)}>About</NavLink>
            <NavLink to="/contact" className={location.pathname === "/contact" ? "activeLink" : ""} onClick={() => setIsOpen(false)}>Contact</NavLink>
            {/* <NavLink to="/support" className={location.pathname === "/support" ? "activeLink" : ""} onClick={() => setIsOpen(false)}>Support Us</NavLink> */}

            {/* Login and Register */}
            <div className="menu-item-group-login">
                {user ? (
                    <button className="menu-item menu-login" onClick={signOut}>Sign Out</button>
                ) : (
                    <button className="menu-item menu-login" onClick={signInWithGoogle}>Sign In</button>
                )}

                {/* Button for settings using settingsIcon as image */}
                <button className="menu-item menu-settings">
                    <img src={settingsIcon} alt="Settings" className="menu-settings-icon" />
                </button>
            </div>
        </Menu>
    );
}
