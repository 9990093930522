import React from 'react';
import aphrodite from 'aphrodite';
import { StyleSheet, css } from 'aphrodite';

// Import Components
import Hero from '../Hero/Hero';


function RatingEstimator() {
    return (
        <div className={css(styles.container)}>
            <Hero />
        </div>
    );
}

// Stylesheet
const styles = StyleSheet.create({
    container: {
        textAlign: "center",
    },
    jumbotron: {
        backgroundColor: "#e9ecef",
        color: "#000000"
    }
});

export default RatingEstimator;
