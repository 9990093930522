import React, { Component } from "react";
import './SupportUs.css';
// import patron icon from fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPatreon } from '@fortawesome/free-brands-svg-icons';

// Home page component
export default class SupportUs extends Component {
    constructor(props) {
        super(props);
        this.state = {
          selectedOption: 'patreon', // This will make 'patreon' the default selected option
        };
    }



    handleOptionChange = (event) => {
        this.setState({ selectedOption: event.target.value });
    };

    // patreon button click handler
    handlePatreonClick() {
        // send user to patreon page in new tab
        window.open("https://www.patreon.com/user?u=49514768", "_blank");
    }

    // feedback button click handler
    handleFeedbackClick() {
        // send user to feedback page
        window.location.href = "/contact";
    }


    render() {
        return (
            <div className="container">
                <div className="jumbotron-background" />
                <div className="jumbotron">
                    <h1 className="title">Can't Wait For More?</h1>
                    <h1 className="sub-title">Consider lending a hand!</h1>
                </div>
                <div className="body">
                    <div className="col">
                        <div className="rocker-pill">
                            <input
                                type="radio"
                                id="patreon"
                                value="patreon"
                                checked={this.state.selectedOption === 'patreon'}
                                onChange={this.handleOptionChange}
                            />
                            <label htmlFor="patreon">Patreon</label>

                            <input
                                type="radio"
                                id="feedback"
                                value="feedback"
                                checked={this.state.selectedOption === 'feedback'}
                                onChange={this.handleOptionChange}
                            />
                            <label htmlFor="feedback">Feedback</label>
                        </div>

                        {this.state.selectedOption === 'patreon' ? (
                            <div>
                                <h2>Become a Patreon</h2>
                                <p classname="description">Please consider subscribing to my Patreon to unlock exclusive perks, influence the development of the app, and to get your name in the credits! Becoming a Patron also helps me so that I can dedicate more time to developing the app. Also seen in the Patreon will be a roadmap, updates, and posts about the development process to see how features are being added! THere will also be lots of engagement opporotunities such as polls for things such as which features get added first, what courses to add, etc., posts and updates will be added as frequently as possible.</p>
                                <br />
                                <h3>Benefits of Becoming a Patron</h3>
                                <ul>
                                    <li>Early Access to new features</li>
                                    <li>Free Premium Version (when it comes around)</li>
                                    <li>Direct contact with dev team, and dev process</li>
                                    <li>Name in credits, regardless of how long or how much you donate</li>
                                    <li>Updates, posts, behind the scenes content, polls, and more (Most posts free, no subscription required)</li>
                                    <li>And more perks to come!</li>
                                </ul>
                        <div className="button-container">
                            <button className="support-buttons" onClick={this.handlePatreonClick}>Become a Patron <FontAwesomeIcon className="faIcons" icon={faPatreon} /></button>
                        </div>
                            </div>
                        ) : (
                            <div>
                                <h2>Feedback & Collaboration</h2>
                                <p className="description">Want to see your favorite course(s) added? Want to suggest a feature for the app? Have I added any typos or is a feature broken? Please let us know so we can provide the best user experience we are able to! Simple get in touch with us at our contact form (linked below), and we will do our best to get back to you! Additionally, if you want more details about the roadmap and plans for this app, please feel free to check out my Patrion page as it will have posts and updates as regularly as possible.</p>
                                <br />
                                <h3>Feedback</h3>
                                <ul>
                                    <li>Report bugs, typos, other issues (using our contact form)</li>
                                    <li>Provide course data for your favorite courses so that they can be added/updated! (Feature to be added)</li>
                                    <li>Give design feedback for the app</li>
                                    <li>Tell us what features you want to see next!</li>
                                </ul>
                        <div className="button-container">
                            <button className="support-buttons" onClick={this.handleFeedbackClick}>Help us imporove!</button>
                        </div>
                            </div>
                        )}
                    </div>
                </div>


                <div className="footer">
                    <p className="footerText">Made with <span className="heart">&#9829;</span> by the DG Course Caddy Team</p>
                </div>
            </div>
        );
    }
}
