import React from 'react';
import { Helmet } from 'react-helmet';
import Logo from '../../Images/Logos/gold-basket-token-2.png';

/**
 * -----------------------------------
 * Header.js
 * -----------------------------------
 */

function Header() {
    // using helmet to change the title of the page
    // set favicon of page to Logo
    return (
        <header className="header">
            <Helmet>
                <title>Disc Golf Score</title>
                <link rel="icon" type="image/png" href={Logo}/>
            </Helmet>

        </header>
    );

};

export default Header;
