import React from "react";
import './About.css';

function About() {
    return (
        <div className="about">
            <div className="about-hero">
                <h1 className="about-title">About</h1>
            </div>
            <div className="about-body">
                <div className="about-body-Content">
                    <h1 className="about-body-Title">Meet the Developer:</h1>
                    <p className="about-body-Text">My name is <a href="https://braydenvernon.com" id="link">Brayden Vernon</a>, a Tulsa based disc golfer. I always think about what my rating for any given round could be. But as someone who doesn't have the time to go play tournaments all the time (on top of costs, travel, etc), simply signing up for every tourney nearby wasn't going to satisfy me. As I was wrapping up my eduaction at Holberton we were tasked with creating a web tool called an API. So of course my mind ran to disc golf. As I sat there thinking about what I'd want to build this tool into, one of my classmates asked if I had been playing any tournaments as of recent and thats when I knew I was going to build a <a href="/" id="link">PDGA Rating Estimator</a></p>
                    <br/>
                    <h3 className="about-body-Title">Cool! Then what?</h3>
                    <p>Thanks for asking! In the inital phases of the project, I worked with my awesome classmates <a href="https://www.caleb.house" id="link">Caleb House</a> and <a href="https://www.connorhostler.tech" id="link">Connor Hostler</a>. My primary role was determining what data we needed, and getting a firebase cloud function to work. Caleb's main task was to set up a front end using React (which we had just started learning). And Connor helped us all by helping us get the front and the back connected. </p>
                    <br/>
                    <h3 className="about-body-Title">Where are we at?</h3>
                    <p>As of now, the project is still in its early stages. The rating estimator is functional, and will become moe accurate over time. Currently we have 23 courses to choose from in the Tulsa Metro area, with more to come as I get more (and better) data. You can also sign in using Google to access your profile, and in doing so it allows you to view your saved rounds!</p>
                    <br/>
                    <h3 className="about-body-Title">Awesome! What's next?</h3>
                    <p>I'm glad you're interested! The next steps I am looking at include:</p>
                    <ul className="about-list">
                        <li>Database Overhaul for more accurate course data</li>
                        <li>User Dashboard so users can create accounts</li>
                        <li>Scorecard functionality so you can save and share your rounds</li>
                        <li>Rating Estimator integration to Scorecards</li>
                        <li>Statistical Insights to rounds (ex: a leaderboard for courses/holes)</li>
                        <li>Individual Stats (ex: you may struggle more on holes that finish left)</li>
                        <li>Much More!</li>
                    </ul>
                    <p>While the app is being developed, please enjoy what is currently availble. If you really enjoy it, consider sharing it with your friends!</p>
                    <p>Have any questions, comments, concerns, or just want to chat? Reach out to us using our <a href="/contact" id="link">Contact Page</a></p>
                </div>
            </div>
            <div className="footer">
                <p className="footerText">Made with <span className="heart">&#9829;</span> by the Disc Golf Score Team</p>
            </div>
        </div>
    );
}

export default About;
