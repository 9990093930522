/**
 * -----------------------------------------------------------------
 * Hero.js
 * -----------------------------------------------------------------
 */

// imports
import React, { Component } from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import Background from '../../Images/Backgrounds/DiscGolfView.jpg'

// datafetch form
import DataFetch from '../DataFetch/DataFetch';

// component
class Hero extends Component {
    render() {
        return (
            <div className={css(styles.hero)}>
                <div className={css(styles.heroContent)}>
                    <DataFetch />
                </div>
            </div>
        );
    }
}

// styles
const styles = StyleSheet.create({
    hero: {
        background: `linear-gradient(180deg, rgb(17, 17, 17), rgb(70,70,70), rgb(23, 23, 23)) `,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        height: '100vh',

        // center content
        display: 'flex',
        justifyContent: 'center',

        // calculate padding top to center content, helps with navbar overlap
        paddingTop: 'calc((100vh / 8))',
    },
    heroContent: {
        color: '#fff',
        textAlign: 'center',

        position: 'relative',

        // fogbox
        backgroundColor: 'rgb(255,242,235)',
        padding: '15px',
        borderRadius: '10px',

        maxHeight: '525px',

        // allow to be wider for mobile devices
        '@media (max-width: 500px)': {
            width: '90%',
        },

    },
});

// export
export default Hero;
