import React from 'react';

// Import components
import './App.css';
import Navbar from './components/Navbar/Navbar';
import Sidebar from './components/Sidebar/Sidebar';
import Header from './components/Header/Header';
import Contact from './components/Contact/Contact';
import UserDashboard from './components/Pages/UserDashboard';

// Routing
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// Pages
import About from './components/Pages/About';
import Courses from './components/Pages/Courses';
import HomePage from './components/Pages/Home';
import NotFoundPage from './components/Pages/NotFound';
import RatingCalculator from './components/Pages/RatingCalculator';
import Scorecard from './components/Pages/Scorecard.js';
import SupportUs from './components/Pages/SupportUs';


function App() {
    return (
      <Router>
        <Navbar />
        <Sidebar />
        <Header />
        <Routes>
          <Route path="/" element={<HomePage/>} />
          <Route path="/rating-calculator" element={<RatingCalculator />} />
          <Route path="/courses" element={<Courses />} />
          <Route path="/scorecard" element={<Scorecard />} />
          <Route path="/userdashboard" element={<UserDashboard />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/support" element={<SupportUs />} />

          {/* This route has no path, and will therefore match any URL that wasn't matched by any of the above */}
          <Route component={NotFoundPage} />
        </Routes>
      </Router>
    );
  }
export default App;
