import React, { useEffect } from 'react';
import { useForm, ValidationError } from '@formspree/react';
import { StyleSheet, css } from 'aphrodite';

function ContactForm() {
    const [state, handleSubmit] = useForm("mjvqlkdl");

    useEffect(() => {
        if (state.succeeded) {
          alert("Thank you for your message! We will get back to you as soon as possible.");
          window.location.href = "/";
        }
    }, [state.succeeded]);

  return (
        <div className={css(styles.container)}>
            <form onSubmit={handleSubmit} className={css(styles.form)}>
            <h1 className={css(styles.formTitle)}>Let's Chat!</h1>
            <br/>
                <label htmlFor="email" className={css(styles.label)}>
                    Email Address
                </label>
                <input className={css(styles.input)}
                    id="email"
                    type="email"
                    name="email"
                />
                <ValidationError
                    prefix="Email"
                    field="email"
                    errors={state.errors}
                />
                <label htmlFor="subject" className={css(styles.label)}>
                    Subject
                </label>
                <input className={css(styles.input)}
                    id="subject"
                    type="subject"
                    name="subject"
                />
                <label htmlFor="message" className={css(styles.label)}>
                    Message
                </label>
                <textarea className={css(styles.textArea)}
                    id="message"
                    name="message"
                />
                <ValidationError
                    prefix="Message"
                    field="message"
                    errors={state.errors}
                />
                <button type="submit" disabled={state.submitting} className={css(styles.submitButton)}>
                    Submit
                </button>
            </form>
        </div>
    );
}

// Stylesheet
const styles = StyleSheet.create({
    container: {
        textAlign: "center",
        backgroundColor: "rgb(255,242,235)",
        borderRadius: "25px",
        width: "50%",
        maxWidth: "500px",
        minWidth: "300px",
        minHeight: "360px",
        height: "auto",
        maxHeight: "500px",
        padding: "20px",
    },
    form: {
        textAlign: "center",
        backgroundColor: "rgb(255,242,235)",
        color: "#000000",
    },

    formTitle: {
        // make fontsize responsive to desktop and mobile
        fontSize: '30px',
        '@media (max-width: 768px)': {
            fontSize: '20px',
        },
    },

    label: {
        display: 'block',
        padding: '5px',

        // make fontsize responsive to desktop and mobile
        fontSize: '20px',
        '@media (max-width: 768px)': {
            fontSize: '12px',
        },
    },
    input: {
        border: '1px solid #232323',
        borderRadius: '10px',
        textAlign: 'center',
        height: '30px',
        width: '75%',
        padding: '6px 12px',
        display: 'inline-block',
        boxSizing: 'border-box',
        margin: '0',
        // make fontsize responsive to desktop and mobile
        fontSize: '16px',
        '@media (max-width: 768px)': {
            width: '100%',
            fontSize: '12px',
        },
    },
    textArea: {
        border: '1px solid #232323',
        borderRadius: '10px',
        textAlign: 'left',
        minHeight: '75px',
        width: '75%',
        padding: '6px 12px',
        display: 'inline-block',
        boxSizing: 'border-box',
        margin: '0',
        // make fontsize responsive to desktop and mobile
        fontSize: '16px',
        '@media (max-width: 768px)': {
            width: '100%',
            fontSize: '12px',
        },
    },
    submitButton: {
        // button styles
        backgroundColor: 'rgb(88, 216, 110)',
        border: '1px solid rgba(23, 23, 23, 0.6)',
        borderRadius: '10px',
        color: '#000',

        // button sizes & positioning
        height: '30px',
        width: '75%',
        padding: '6px 12px',
        textAlign: 'center',
        display: 'inline-block',
        boxSizing: 'border-box',
        marginTop: '30px',
        marginBottom: '20px',

        // make fontsize responsive to desktop and mobile
        fontSize: '16px',
        '@media (max-width: 768px)': {
            width: '100%',
            fontSize: '12px',
        },

        // hover effect, turn button green over 0.5s
        ':hover': {
            backgroundColor: '#32CD32',
            color: '#fff',
            textShadow: '2 2 3px rgb(23, 23, 23)',
            transition: '0.5s',
        },
        // transition the button back after not hovering over it
        ':not(:hover)': {
            transition: '1.0s',
        },
    }
});


export default ContactForm;