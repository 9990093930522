import React from "react";
import './Home.css';

// Home page component

function Home() {
    return (
        <div className="home">
            <svg style={{ height: 0, width: 0, position: 'absolute' }}>
                <filter id="blur">
                    <feGaussianBlur stdDeviation="5" />
                </filter>
            </svg>
            <div className="heroBackground" />
            <div className="hero">
                <div className="heroContent">
                    <h1 className="home-title">Disc Golf Score</h1>
                    <h2 className="home-subtitle">By Disc Golfers, For Disc Golfers</h2>
                </div>
            </div>
            <div className="home-body">
                <div className="home-body-Content">
                    <h1 className="home-body-Title">Welcome!</h1>
                    <p className="home-body-Text">Disc Golf Score is an app aimed to help disc golfers at all skill levels by providing them with data that will help them improve. Currently we have a <a href="rating-calculator" id="link">Rating Calculator</a> that allows you to generate PDGA rating estimations on select courses. Signing in with Google, users can save and view scores.</p>
                    <p className="home-body-Text">Disc Golf Score is a work in progress. Please be patient as we continue to develop and improve the app. If you have any questions or suggestions, please reach out using our <a href="contact" id="link">Contact Form</a></p>
                    <a href="/rating-calculator" class="redirect-button">Get Started</a>
                </div>
            </div>
            <div className="footer">
                <p className="footerText">Made with <span className="heart">&#9829;</span> by the DG Course Caddy Team</p>
            </div>
        </div>

    );
}

export default Home;
