import React, { Component } from "react";
import Aphrodite from "aphrodite";
import { StyleSheet, css } from "aphrodite";

// Home page component
export default class Courses extends Component {
    render() {
        return (
            <div className="container">
                <div className="jumbotron">
                    <h1>Courses Page</h1>
                    <h1 className="display-4">Welcome to the DG Caddy Companion!</h1>
                    <p className="lead">This page is still under construction. Thank you for your patience and thank you for coming by!</p>
                    <p><a href="/">Return to Home</a></p>
                </div>
            </div>
        );
    }
}

// Stylesheet
const styles = StyleSheet.create({
    container: {
        textAlign: "center",
        marginTop: "10px",
        marginBottom: "10px"
    },
    jumbotron: {
        backgroundColor: "#e9ecef",
        color: "#000000"
    }
});
