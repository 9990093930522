import React from "react";
import "./Scorecard.css";

// Home page component
function Scorecard() {

    // get the user object from local storage
    let user = "";
    // get user from local storage
    if (localStorage.getItem("user")) {
        user = JSON.parse(localStorage.getItem("user"));
    }
    else {
        // redirect home if user is not signed in
        alert("You must be signed in to view this page.");
        window.location.href = "/";
    }

    // get user info from user variable
    let userSavedRounds = user.userSavedRounds ? user.userSavedRounds.rounds : [];
    userSavedRounds = JSON.parse(JSON.stringify(userSavedRounds));

    return (
        <div className="scorecard-container">
            <div className="scorecard-jumbotron">
            </div>
            <div className="userSavedRounds">
                <div className="userSavedRoundsTitle">Saved Rounds</div>
                <div className="userSavedRoundsList">
                    <div className="userTitle">Course</div>
                    <div className="userTitle">Date</div>
                    <div className="userTitle">Time</div>
                    <div className="userTitle">Score</div>
                    <div className="userTitle">Rating</div>
                    <div className="userTitle">Date Saved</div>
                    {userSavedRounds && userSavedRounds.map((round, index) => (
                        <div className="userSavedRound" key={index}>
                            <div className="userSavedRoundName">{round.course}</div>
                            <div className="userSavedRoundDate">{round.date}</div>
                            <div className="userSavedRoundTime">{round.time}</div>
                            <div className="userSavedRoundScore">{round.score}</div>
                            <div className="userSavedRoundRating">{round.rating}</div>
                            <div className="userSavedRoundDateSaved">
                                {typeof round.dateSaved === 'string'
                                    ? round.dateSaved.substring(0, 10)
                                    : new Date(round.dateSaved.seconds * 1000).toISOString().substring(0, 10)}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <p>More features to be added later.</p>

        </div>
    );
}

export default Scorecard;
