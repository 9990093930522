import React, { Component } from "react";
import { css, StyleSheet } from "aphrodite/no-important";
import Logo from "../../Images/Logos/gold-basket-token-1.png";

/**
 * -----------------------------------
 * Navbar.js
 * -----------------------------------
 */

const returnHome = () => {
    console.log("returnHome");
    window.location.href = "/";
}

class Navbar extends Component {

    render() {
        return(
            <div className={css(styles.navbar)}>

                <button className={css(styles.navbarLogo)} onClick={returnHome} />
                <h1 className={css(styles.navbarTitle)}>Disc Golf Score</h1>

            </div>
        );
    }

}

// Aphrodite CSS
const styles = StyleSheet.create({
    // name the navbar logo image smaller
    navbarLogo: {
        height: "50px",
        width: "50px",
        backgroundImage: `url(${Logo})`,
        // add white shadow to logo
        boxShadow: "0px 0px 5px 1px rgba(255,255,255,0.0)",
        // fill boxShadow with white
        backgroundColor: "rgba(255,255,255,0.0)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        alignItems: "center",
        justifyContent: "center",
        padding: 0,
        border: "none",
        cursor: "pointer",
    },
    navbar: {
        // make navbar position fixed
        position: "fixed",
        height: "50px",
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: "rgba(23,23,23,1)",
        padding: "1rem",
        zIndex: "999",

    },
    Sidebar: {
        zIndex: "999",
    },

    navbarTitle: {
        color: "#fff2eb",
        fontFamily: "Roboto, sans-serif",
        fontWeight: "bold",
        fontSize: "1.5rem",
        margin: 0,
        textAlign: "center",
        marginLeft: "1rem",

        /* on mobile, center absolutely */
        "@media (max-width: 768px)": {
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            fontSize: "1.25rem",
            marginLeft: 0,
        },

    },

});


export default Navbar;
