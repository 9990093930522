import { getAuth, onAuthStateChanged, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { getFirestore, doc, getDoc, setDoc } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAnFlYsPQtPOSn2at1_Mx93Jjl4pAamKvU",
  authDomain: "pdga-rating-estimator.firebaseapp.com",
  databaseURL: "https://pdga-rating-estimator-default-rtdb.firebaseio.com",
  projectId: "pdga-rating-estimator",
  storageBucket: "pdga-rating-estimator.appspot.com",
  messagingSenderId: "429033766049",
  appId: "1:429033766049:web:18f3113414eb38867d44e0",
  measurementId: "G-W7H9F7ELKF"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
export { db };

export const auth = getAuth(app);

const googleProvider = new GoogleAuthProvider();

export const signInWithGoogle = () => {
  signInWithPopup(auth, googleProvider).then((result) => {

    // user sign in successful
    console.log("User {user.displayName} sign in successful");
    console.log(result);

    // redirect to user dashboard
    window.location.assign('/userdashboard');


  }).catch((error) => {
    // Handle Errors here.
    var errorCode = error.code;
    var errorMessage = error.message;
    console.log("Error code: " + errorCode);
    console.log("Error message: " + errorMessage);
  });
};

export const observeAuthState = (callback) => {

    return onAuthStateChanged(auth, async (user) => {
      if (user) {
        // User is signed in, create user object
        const userObj = {
            name: user.displayName,
            email: user.email,
            photoUrl: user.photoURL,
            uid: user.uid,
            userSavedRounds: {
                rounds: [],
            },
        };

        // save user object to local storage
        localStorage.setItem('user', JSON.stringify(userObj));

        // check if user document already exists in Firestore
        const userDock = doc(db, "users", user.uid);
        const docSnap = await getDoc(userDock);

        // if user document doesn't exist, save user object to Firestore
        if (!docSnap.exists()) {
            await setDoc(userDock, userObj);
        }

        // if the user does exist in the database, update the user object in local storage
        else if (docSnap.exists()) {
            let userObj = docSnap.data();
            localStorage.setItem('user', JSON.stringify(userObj));
        }

        // // if it does exist, and the user has saved rounds, update the user object in local storage
        // else if (docSnap.exists() && docSnap.data().userSavedRounds.rounds.length > 0) {
        //     let userObj = docSnap.data();
        //     localStorage.setItem('user', JSON.stringify(userObj));
        // }

        // Call the callback function with the user object
        callback(userObj);

      } else {
        // User is signed out
        // You can handle the sign-out situation here

        // Call the callback function with null
        callback(null);
      }
    });
  };
